.orderCardTooltip {
  position: relative;
}

.orderCardTooltip:before {
  content: attr(data-text);
  position: absolute;

  transform: translateX(-14%);

  /* move to top */
  bottom: 100%;
  margin-bottom: 12px;

  /* basic styles */
  width: 200px;
  padding: 4px;
  border-radius: 4px;
  border: 1px dotted #9a978f;
  opacity: 0.9;
  background: #f1f1f1;
  color: #000000;
  text-align: center;

  display: none;
}

.orderCardTooltipWithTruck:before {
  transform: translateX(-26%);
}

.orderCardTooltip:hover:before {
  display: block;
}
